import React, { ReactElement } from 'react';
import { getCsrfToken } from 'next-auth/react';
import AuthLayout from '../../layouts/AuthLayout';
import { Button, Input, SPACING } from '@homee/ui-web-presentation';
import { CustomNextPage, PageRoles } from '../../lib/types/next';
import { Text } from '@homee/ui-web-presentation';
import { useRouter } from 'next/router';
import { COLOR } from '@homee/ui-web-presentation';
import Link from 'next/link';
import Head from 'next/head';

enum SignInError {
  OAuthSignin = 'OAuthSignin',
  OAuthCallback = 'OAuthCallback',
  OAuthCreateAccount = 'OAuthCreateAccount',
  EmailCreateAccount = 'EmailCreateAccount',
  Callback = 'Callback',
  OAuthAccountNotLinked = 'OAuthAccountNotLinked',
  EmailSignin = 'EmailSignin',
  CredentialsSignin = 'CredentialsSignin',
  SessionRequired = 'SessionRequired',
}

const SignIn: CustomNextPage<{ csrfToken: string }> = ({ csrfToken }) => {
  const { error } = useRouter().query;

  const passwordErrorMessage =
    error === SignInError.CredentialsSignin
      ? 'Login Failed, incorrect credentials'
      : undefined;

  return (
    <>
      <Head>
        <title>Sign In | HOMEE</title>
      </Head>
      <div className="container">
        <Text className="signin-blurb">
          Manage your account, view job history, and check the details or your
          jobs.
        </Text>

        <form
          className="signin-form"
          method="post"
          action="/api/auth/callback/homee-credentials"
        >
          <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <Input
            className="user-input"
            label="Email"
            id="login-email"
            name="email"
            type="email"
          />
          <Input
            className="user-input"
            hasVisibilityToggle
            id="login-password"
            label="Password"
            name="password"
            type="password"
            isInvalid={!!passwordErrorMessage}
            helperText={passwordErrorMessage}
          />
          <Link href="/auth/forgot-password" className="forgot-password">
            <Text color="link" size="small" element="span">
              Forgot password
            </Text>
          </Link>
          <Button
            type="submit"
            id="login-submit"
            size="large"
            style={{
              textTransform: 'uppercase',
              width: '100%',
            }}
          >
            Login
          </Button>
        </form>
      </div>
      <style jsx>
        {`
          .container {
            margin: ${SPACING.md}px ${SPACING.md}px;
          }
          .container :global(.user-input) {
            width: 100%;
            margin-bottom: 16px;
          }

          .container :global(.signin-blurb) {
            margin-bottom: 32px;
            color: ${COLOR.darkGray} !important;
          }

          .signin-form {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .container :global(.forgot-password) {
            margin-bottom: ${SPACING.xl}px;
          }
        `}
      </style>
    </>
  );
};
export async function getServerSideProps(context) {
  return {
    props: {
      csrfToken: await getCsrfToken(context),
    },
  };
}

// Custom sign in page requires its own layout otherwise it will use the main layout
SignIn.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>;
};
SignIn.allowedRoles = [PageRoles.Public];

export default SignIn;
