import type { FC } from 'react';
import Image from 'next/image';
import {
  Text,
  ChildrenProps,
  SPACING,
  COLOR,
} from '@homee/ui-web-presentation';
import classnames from 'classnames';

interface AuthLayoutProps extends ChildrenProps {
  className?: string;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children, className }) => {
  return (
    <div className="container">
      <div className={classnames('children', className)}>
        <div className="title-heading">
          <Image
            src="/pro-portal-logo-2023.svg"
            alt="HOMEE PRO PORTAL"
            priority
            fill
            style={{ marginLeft: `-${SPACING.sm}px` }}
          />
        </div>
        {children}
      </div>
      <div className="copyright-container">
        <Text onDark size="medium">
          © Copyright 2018 Homee, Inc. All rights reserved. Patent Pending.
        </Text>
        <Text onDark size="small">
          Licensed in AZ #ROC315260, CA 1038384, CT HIC.0649964 HIS.0559982,
          CIty of Chicago TGC1021061, FL #CGC1512633, PA HIC#PA135847, NJ
          HIC#13VH09598500, NV 0083400, UT 10798602-5501
        </Text>
      </div>
      <style jsx>
        {`
          .container {
            min-height: 100vh;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            background: url('/signin-background.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #1f3f51;
            background-blend-mode: color-burn;
          }

          .children {
            background-color: ${COLOR.white};
            max-width: 475px;
            height: fit-content;
            padding: 56px 40px;
            margin: ${SPACING.xl}px ${SPACING.md}px;
            letter-spacing: 0.5px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }

          .children.reset-password {
            min-height: auto;
          }

          .title-heading {
            position: relative;
            height: 100px;
            margin-bottom: 24px;
          }

          .copyright-container {
            text-align: center;
            margin: ${SPACING.md}px 0;
          }
        `}
      </style>
    </div>
  );
};

export default AuthLayout;
